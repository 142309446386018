@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
:root {
  --background: #060307;
  --accent: #a3df18;
  --fwm: 90%;
}
a {
  text-decoration: none;
}
.font-f {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  color: white;
}
.font-s {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}
.fw-l {
  font-weight: 300;
}
.fw-m {
  font-weight: 400;
}
.fw-b {
  font-weight: 500;
}
.fz-s {
  font-size: 2vh;
}
.fz-m {
  font-size: 2.1vh;
}
.fz-mb {
  font-size: 2.4vh;
}
.fz-b {
  font-size: 3.3vh;
}
.underline {
  width: 230px;
  height: 3px;
  background-color: var(--accent);
}
.App {
  background-color: var(--background);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-block: 0vh;
  .nav {
    display: block;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    width: 100%;
    height: 70px;
    position: fixed;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(32px);
    z-index: 999;
    .nav-item {
      margin-inline: 6vh;
      img {
        width: 90%;
      }
    }
  }
  .navbar {
    width: 100vw;
    height: 70px;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    position: absolute;
    top: 0;
    .navbar_container {
      height: 100%;
      margin-inline: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .navbar_item {
        display: flex;
        gap: 6px;
        .navbar_item-logo {
          width: 85%;
        }
        .navbar_item-caret {
          width: 25%;
        }
      }
      .navbar_item {
        position: relative;
        .lang-list {
          position: absolute;
          width: 40px;
          margin-top: 60px;
          margin-left: -8px;
          border-radius: 12px;
          background-color: rgba(0, 0, 0, 0.7);
          backdrop-filter: blur(32px);
          padding: 10px;
        }
      }
      .navbar-desktop {
        width: 600px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .separator {
          height: 25px;
          width: 1px;
          background-color: rgb(255, 255, 255, 0.1);
        }
      }
    }
  }
  .container {
    background-image: radial-gradient(
      circle at 1px 1px,
      rgb(255, 255, 255, 0.5) 1px,
      transparent 0
    );
    background-size: 29.5vw 29.5vw;
    width: var(--fwm);
    .main {
      display: flex;
      justify-content: center;
      .main_cont {
        padding-top: 100px;
        display: grid;
        grid-template-rows: 50px 50px 45vh 100px 160px 50px;
        justify-content: center;
      }
      .text{
        line-height: 30px;
        width: 95%;
      }
      .title {
        letter-spacing: 10px;
        font-size: 12vw;
      }
      .model-mobile {
        align-items: start;
      }
      .description {
        .highlited {
          color: var(--accent);
        }
      }
      .contact {
        margin-top: -100px;
        opacity: 30%;
      }
      .socials {
        position: absolute;
        top: 360px;
        right: 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        img {
          width: 100%;
        }
        display: none;
      }
      .main_item {
        .order {
          z-index: 997;
          background-color: #080749;
          width: 60%;
          height: 35%;
          border-radius: 32px;

        }
      }
    }
    .portfolio {
      padding-top: 15px;
      display: grid;
      grid-template-rows: 50px 75vh;
      .project {
        border-radius: 12px;
        place-self: center;
        width: 85%;
        background-color: rgb(0, 0, 0, 0.7);
        backdrop-filter: blur(3px);
        padding: 10px;
        padding-inline: 15px;
        .project_link {
          display: flex;
          gap: 5px;
        }
        img {
          margin-block: 5px;
          border-radius: 12px;
          width: 100%;
        }
      }
    }
    .prices {
      padding-top: 15px;
      display: grid;
      grid-template-rows: 200px;
      gap: 30px;
      padding-bottom: 100px;
      .prices-desk {
        display: grid;
        grid-template-rows: 370px 370px 370px;
        gap: 30px;
        .prices_item {
          padding-block: 20px;
          gap: 70px;
          width: 90%;
          text-align: center;
          justify-self: center;
          background-color: rgb(0, 0, 0, 0.5);
          backdrop-filter: blur(3px);
          border-radius: 12px;
          .price-old {
            opacity: 30%;
            text-decoration: line-through;
          }
          .price-new {
            color: var(--accent);
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .fw-l {
    font-weight: 300;
  }
  .fw-m {
    font-weight: 400;
  }
  .fw-b {
    font-weight: 500;
  }
  .fz-s {
    font-size: 18px;
  }
  .fz-m {
    font-size: 20px;
  }
  .fz-mb {
    font-size: 24px;
  }
  .fz-b {
    font-size: 26px;
  }
  .App {
    display: flex;
    justify-content: center;
    .nav {
      overflow: hidden;
      display: none;
    }
    .navbar {
      width: 100%;
      display: flex;
      justify-content: center;
      position: fixed;
      top: 0;
      background-color: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(32px);
      z-index: 999;
      .navbar_container {
        width: 70%;
      }
    }
    .container {
      background-image: radial-gradient(
        circle at 1px 1px,
        rgb(255, 255, 255, 0.5) 1px,
        transparent 0
      );
      background-size: 5.3vw 5.5vw;
      width: 97%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .main {
        width: 80%;
        height: 100vh;
        display: flex;
        justify-content: space-evenly;
        .main_cont {
        }
        .title {
          letter-spacing: 20px;
          font-size: 82px;
        }
        .main_cont {
          padding-top: 230px;
          display: grid;
          gap: 10px;
          grid-template-rows: 20px 70px 150px 70px 110px;
        }
        .main_item {
          display: flex;
          align-items: center;

          .order {
            z-index: 998;
            width: 230px;
            height: 50px;
          }
        }
        .model-mobile {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 100px;
        }
        .model {
        }
        .description {
          .text {
            
            width: 600px;
          }
        }
        .contact {
          opacity: 100%;
        }
        .socials {
          display: none;
        }
        .desk-icons {
          display: flex;
          justify-content: space-between;
          padding-left: 15px;
          width: 92%;
          .desk-icon {
            display: flex;
            gap: 5px;
            white-space: nowrap;
          }
        }
      }
      .portfolio {
        padding-top: 100px;
        display: grid;
        grid-template-rows: 50px 930px;
        height: 97vh;
        .project {
          border-radius: 12px;
          place-self: center;
          width: 35%;
          background-color: rgb(0, 0, 0, 0.7);
          backdrop-filter: blur(3px);
          padding: 20px;
          padding-inline: 15px;
          .project_link {
            display: flex;
            gap: 5px;
          }
          img {
            margin-block: 5px;
            border-radius: 12px;
            width: 90%;
          }
          .desc {
            width: 90%;
          }
        }
      }
      .prices {
        padding-block: 100px;
        display: grid;
        grid-template-rows: 50px;
        gap: 30px;
        .prices-desk {
          padding-top: 100px;
          padding-bottom: 150px;
          display: grid;
          grid-template-columns: 420px 420px 420px;
          gap: 30px;
          grid-template-rows: 370px;
          .prices_item {
            position: relative;
            padding-block: 25px;
            gap: 50px;
            width: 90%;
            text-align: center;
            justify-self: center;
            background-color: rgb(0, 0, 0, 0.5);
            backdrop-filter: blur(3px);
            border-radius: 12px;
            .price_tag{
              padding-top: 10px;
              position: absolute;
              bottom: 15px;
              .price-old {
                opacity: 30%;
                text-decoration: line-through;
              }
              .price-new {
                color: var(--accent);
              }
            }
          }
        }
      }
    }
  }
}
