.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    position: relative;
    color: white;
    img{
      margin-right: 5px;
    }
  }
  
  .carousel-text {
    text-align: center;
    padding: 1rem;
    max-width: 90%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    opacity: 0;
    transform: translateX(100%);
    animation: slideIn 0.5s forwards;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }
  
  @media (max-width: 768px) {
    .carousel-text {
      
    }
  }
  